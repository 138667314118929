import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { decoratedAxiosPost } from "../../req_utils";
import { GENESIS_LOGO_COLOR } from "../../constants";
import { GENESIS_LOGO_BASE64 } from "../../genesis_logo_base64";
import { GENESIS_UTAH_LOGO_BASE64 } from "../../genesis_utah_logo_base64";
import { BUSINESS_BG_BASE64 } from "../../business_bg_base64";
import { COURGETTE_FONT_BASE64 } from "../../courgette_font_base64";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { selectProposalAndRefreshProposals } from "../../reducers";
import { decoratedFetch } from "../../req_utils";
import { Checkbox, Typography } from "@mui/material";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.vfs["Courgette-Regular.ttf"] = COURGETTE_FONT_BASE64;
pdfMake.fonts = {
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
  Courgette: {
    normal: "Courgette-Regular.ttf",
    bold: "Courgette-Regular.ttf",
  },
};
const DIVIDER_COLOR = "#D3D3D3";
const HYPERLINK_COLOR = "#2E67D1";
const SECONDARY_COLOR = "#005691";
const COVER_PAGE_X_MARGIN = 40;

const CreateProposalDialog = ({
  open,
  handleClose: handleCloseProp,
  proposal,
  customer = {},
  location = {},
  startDate = null,
  submittedBy = {},
  isExistingProposal,
}) => {
  const dispatchState = useDispatch();
  const project = useSelector((state) => state.project);
  const [proposalName, setProposalName] = useState("");
  const [proposalNameError, setProposalNameError] = useState(false);
  const [includedArea, setIncludedArea] = useState("");
  const [inclusions, setInclusions] = useState([]);
  const [exclusions, setExclusions] = useState([]);
  const [inclusionInput, setInclusionInput] = useState("");
  const [exclusionInput, setExclusionInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isItemized, setIsItemized] = useState(false);
  const [includeMFL, setIncludeMFL] = useState(false);
  const [includeSalesTax, setIncludeSalesTax] = useState(false);
  const [selectedInclusions, setSelectedInclusions] = useState([]);
  const [selectedExclusions, setSelectedExclusions] = useState([]);
  const [materialTotal, setMaterialTotal] = useState(0);
  const [laborTotal, setLaborTotal] = useState(0);
  const [freightTotal, setFreightTotal] = useState(0);
  const [nextInclusionId, setNextInclusionId] = useState(1);
  const [nextExclusionId, setNextExclusionId] = useState(1);
  const [nextNoteId, setNextNoteId] = useState(1);
  const [proposalTemplates, setProposalTemplates] = useState([]);
  const [proposalTemplate, setProposalTemplate] = useState("");
  const [notes, setNotes] = useState([]);
  const [selectedNotes, setSelectedNotes] = useState([]);
  const [taxableTotal, setTaxableTotal] = useState(0);
  const [addedSalesTax, setAddedSalesTax] = useState(0);
  const [totalAfterSalesTax, setTotalAfterSalesTax] = useState(0);

  const handleIsItemizedChecked = (e) => {
    setIsItemized(e.target.checked);
    setIncludeMFL(false);
    setIncludeSalesTax(false);
  };

  const handleProposalTemplateChange = (e) => {
    const selectedTemplateId = e.target.value;
    setProposalTemplate(selectedTemplateId);

    decoratedFetch(`/look_up_proposal_template?id=${selectedTemplateId}`)
      .then((response) => response.json())
      .then((data) => {
        const inclusionsWithIds =
          data?.inclusions?.map((description, index) => ({
            id: index,
            description,
          })) || [];
        setNextInclusionId(inclusionsWithIds.length);
        const exclusionsWithIds =
          data?.exclusions?.map((description, index) => ({
            id: index,
            description,
          })) || [];
        setNextExclusionId(exclusionsWithIds.length);
        const notesWithIds =
          data?.notes?.map((description, index) => ({
            id: index,
            description,
          })) || [];
        setNextNoteId(notesWithIds.length);

        setInclusions(inclusionsWithIds);
        setExclusions(exclusionsWithIds);
        setNotes(notesWithIds);
      })
      .catch((error) => {
        console.error(`Error listing proposal templates: ${error}`);
      });
  };

  const descriptionColumns = [
    {
      field: "description",
      headerName: "Description",
      editable: true,
      flex: 1,
    },
  ];

  const handleAddNoteRow = () => {
    const newRow = { id: nextNoteId, description: "" };
    setNotes([...notes, newRow]);
    setNextNoteId(nextNoteId + 1);
  };

  const handleNoteRowDelete = () => {
    setNotes((prevRows) =>
      prevRows.filter((row) => !selectedNotes.includes(row.id)),
    );
    setSelectedNotes([]); // Clear selection after deletion
  };

  const handleNoteRowSelectionModelChange = useCallback(
    (newSelectionModel) => {
      setSelectedNotes(newSelectionModel);
    },
    [setSelectedNotes],
  );

  const handleNoteEditCommit = (updatedRow) => {
    const newRows = notes.map((row) => {
      if (row.id === updatedRow.id) {
        return { ...row, ...updatedRow };
      }
      return row;
    });
    setNotes(newRows);
    return updatedRow;
  };

  const handleExclusionEditCommit = (updatedRow) => {
    const newExclusions = exclusions.map((row) => {
      if (row.id === updatedRow.id) {
        return { ...row, ...updatedRow };
      }
      return row;
    });
    setExclusions(newExclusions);
    return updatedRow;
  };

  const handleAddExclusionRow = () => {
    const newRow = { id: nextExclusionId, description: "" };
    setExclusions([...exclusions, newRow]);
    setNextExclusionId(nextExclusionId + 1);
  };

  const handleExclusionRowDelete = () => {
    setExclusions((prevExclusions) =>
      prevExclusions.filter((row) => !selectedExclusions.includes(row.id)),
    );
    setSelectedExclusions([]); // Clear selection after deletion
  };

  const handleExclusionRowSelectionModelChange = useCallback(
    (newSelectionModel) => {
      setSelectedExclusions(newSelectionModel);
    },
    [setSelectedExclusions],
  );

  const handleRowEditCommit = (updatedRow) => {
    const newRows = inclusions.map((row) => {
      if (row.id === updatedRow.id) {
        const quantity = parseFloat(updatedRow.quantity) || 0;
        const unitPriceString = updatedRow.unitPrice ? updatedRow.unitPrice.toString() : "";
        const unitPrice =
          parseFloat(unitPriceString.replace(/[^0-9.-]+/g, "")) || 0;
        return {
          ...row,
          ...updatedRow,
          unitPrice: `$${unitPrice}`,
          extension: `$${(quantity * unitPrice).toFixed(2)}`,
        };
      }
      return row;
    });
    setInclusions(newRows);
    return updatedRow;
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    calculateTotals(inclusions);
  }, [inclusions, isItemized, includeSalesTax, open]);

  const handleCellEditStop = (params, event) => {
    console.log("hit");
    const { id, field } = params;
    if (event.key === "Enter" || event.type === "blur") {
      setInclusions((prevRows) => {
        const newRows = prevRows.map((row) => {
          if (row.id === id) {
            const updatedRow = { ...row };
            if (
              field === "quantity" ||
              field === "unitPrice" ||
              field === "category"
            ) {
              const quantity = parseFloat(updatedRow.quantity) || 0;
              const unitPriceString = updatedRow.unitPrice.toString();
              const unitPrice =
                parseFloat(unitPriceString.replace(/[^0-9.-]+/g, "")) || 0;
              const extension = (quantity * unitPrice).toFixed(2);
              updatedRow.unitPrice = `$${unitPrice}`;
              updatedRow.extension = `$${extension}`;
            }
            return updatedRow;
          }
          return row;
        });
        return newRows;
      });
    }
  };

  const handleAddInclusionRow = () => {
    const newRow = {
      id: nextInclusionId,
      quantity: "",
      unit: "",
      description: "",
      unitPrice: "",
      extension: "",
      category: "",
    };
    setInclusions([...inclusions, newRow]);
    setNextInclusionId(nextInclusionId + 1);
  };

  const handleRowDelete = () => {
    setInclusions((prevRows) =>
      prevRows.filter((row) => !selectedInclusions.includes(row.id)),
    );
    setSelectedInclusions([]); // Clear selection after deletion
  };

  const itemizedColumns = [
    {
      field: "quantity",
      headerName: "Quantity",
      type: "number",
      editable: true,
    },
    { field: "unit", headerName: "Unit", editable: true },
    {
      field: "category",
      headerName: "Category",
      editable: true,
      width: 175,
      type: "singleSelect",
      valueOptions: ["Material", "Freight", "Labor"],
    },
    {
      field: "description",
      headerName: "Description",
      editable: true,
      width: 380,
    },
    {
      field: "unitPrice",
      headerName: "Unit Price",
      type: "number",
      editable: true,
    },
    {
      field: "extension",
      headerName: "Extension",
      type: "number",
      editable: false,
    },
  ];

  const handleInclusionRowSelectionModelChange = useCallback(
    (newSelectionModel) => {
      setSelectedInclusions(newSelectionModel);
    },
    [setSelectedInclusions],
  );

  const calculateTotals = (newRows) => {
    let materialTotal = 0;
    let laborTotal = 0;
    let freightTotal = 0;

    if (!isItemized) {
      if (isExistingProposal) {
        materialTotal = proposal.material_subtotal || 0;
        laborTotal = proposal.labor_subtotal || 0;
        freightTotal = proposal.freight_subtotal || 0;
      } else {
        materialTotal = project.material_subtotal || 0;
        laborTotal = project.labor_subtotal || 0;
        freightTotal = project.freight_subtotal || 0;
      }
    } else {
      newRows.forEach((row) => {
        if (!row.extension) {
          return;
        }
        const extension =
          parseFloat(row.extension.replace(/[^0-9.-]+/g, "")) || 0;
        switch (row.category) {
          case "Material":
            materialTotal += extension;
            break;
          case "Labor":
            laborTotal += extension;
            break;
          case "Freight":
            freightTotal += extension;
            break;
          default:
            break;
        }
      });
    }

    if (includeSalesTax) {
      let salesTaxRate = 0;
      if (isExistingProposal) {
        salesTaxRate = parseFloat(proposal.material_sales_tax_rate) / 100;
      } else {
        salesTaxRate = parseFloat(project.material_sales_tax_rate) / 100;
      }
      const taxableTotal = parseFloat(materialTotal) + parseFloat(freightTotal);
      const addedSalesTax = taxableTotal * salesTaxRate;
      const totalAfterSalesTax =
        parseFloat(materialTotal) +
        parseFloat(freightTotal) +
        parseFloat(laborTotal) +
        addedSalesTax;
      setTaxableTotal(taxableTotal);
      setAddedSalesTax(addedSalesTax);
      setTotalAfterSalesTax(totalAfterSalesTax);
    }

    setMaterialTotal(materialTotal.toFixed(2));
    setLaborTotal(laborTotal.toFixed(2));
    setFreightTotal(freightTotal.toFixed(2));
  };

  const handleIncludeMFL = (e) => {
    setIncludeMFL(e.target.checked);
  };

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    handleCloseProp();
  };

  const getPDFBlob = (docDefinition) => {
    return new Promise((resolve, reject) => {
      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      pdfDocGenerator.getBuffer((buffer) => {
        const blob = new Blob([buffer], { type: "application/pdf" });
        resolve(blob);
      });
    });
  };

  const generateUnItemizedTable = (rows) => {
    const descriptions = rows.map((row) => row.description);
    return [
      {
        ol: descriptions.map((inclusion) => inclusion.trim()),
        margin: [10, 0, 0, -3],
        fontSize: 9,
      },
    ];
  };

  const generateItemizedTable = (rows) => {
    const tableBody = [
      [
        { text: "Quantity", bold: true, fontSize: 8, margin: [4, 0, 0, 0] },
        { text: "Unit", bold: true, fontSize: 8 },
        { text: "Description", bold: true, fontSize: 8 },
        { text: "Unit Price", bold: true, fontSize: 8 },
        { text: "Extension", bold: true, fontSize: 8 },
      ],
      ...rows.map((row) => [
        { text: row.quantity, fontSize: 8, margin: [4, 0, 0, 0] },
        { text: row.unit, fontSize: 8 },
        { text: row.description, fontSize: 8 },
        { text: row.unitPrice, fontSize: 8 },
        { text: row.extension, fontSize: 8 },
      ]),
    ];

    return {
      table: {
        headerRows: 1,
        widths: ["10%", "10%", "50%", "15%", "15%"],
        body: tableBody,
      },
      layout: "lightHorizontalLines",
      margin: [10, 0, 0, -3],
      fillColor: "white",
    };
  };

  const handleSubmit = async () => {
    if (!proposalName) {
      setProposalNameError(true);
      return;
    }

    try {
      setLoading(true);

      const branch_address = project?.genesis_branch_address;
      const is_ca_branch = branch_address?.state === "CA";
      let branch_address_line_1 = "";
      let branch_address_line_2 = "";
      let branch_address_phone_no = "";
      let branch_address_contractor_no = "";
      let branch_address_dir_no = "";
      let ca_wages_clause = "";
      if (branch_address) {
        branch_address_line_1 = `${branch_address.line_1}${branch_address.line_2 ? ` ${branch_address.line_2}` : ""}\n`;
        branch_address_line_2 = `${branch_address.city}, ${branch_address.state} ${branch_address.zip}\n`;
        branch_address_phone_no = `Tel: ${branch_address.phone_no}\n`;
        branch_address_contractor_no = `Contractor Lic. #${branch_address.contractor_no}\n`;
        branch_address_dir_no = `DIR #${branch_address.dir_no}\n`;
        if (is_ca_branch) {
          ca_wages_clause = "Labor is based on Prevailing Wages.";
        }
      }

      const customer_address = project?.account?.address;
      let customer_name = "";
      let company_name = "";
      let company_address_line_1 = "";
      let company_address_line_2 = "";
      let customer_phone_no = "";
      if (customer_address) {
        customer_name = `${customer.first_name} ${customer.last_name}`;
        company_name = project.account.name;
        company_address_line_1 = `${customer_address.line_1}${customer_address.line_2 ? ` ${customer_address.line_2}` : ""}`;
        company_address_line_2 = `${customer_address.city}${`, ${customer_address.state}${` ${customer_address.zip}`}`}`;
        customer_phone_no = customer.cell_no;
      }

      let projectStartDate = "";
      let projectStartYear = "";
      if (startDate) {
        const startDateObj = new Date(startDate);
        projectStartYear = startDateObj.getFullYear();
        projectStartDate = startDateObj.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      }

      const project_name = project?.name ?? "";

      let location_line_1 = "";
      let location_line_2 = "";
      if (location && location.address) {
        location_line_1 = `${location.address?.line_1}${location.address.line_2 ? ` ${location.address.line_2}` : ""}`;
        location_line_2 = `${location.address?.city}${`, ${location.address?.state}${` ${location.address?.zip}`}`}`;
      }

      const p_bond_num = parseFloat(
        proposal.p_and_p_bond ? proposal.p_and_p_bond : "0",
      );

      let total_num = 0;
      let subtotal = "";
      let subtotal_num = 0;
      if (isItemized) {
        total_num =
          parseFloat(materialTotal) +
          parseFloat(freightTotal) +
          parseFloat(laborTotal) +
          parseFloat(p_bond_num);
        subtotal_num =
          parseFloat(materialTotal) +
          parseFloat(freightTotal) +
          parseFloat(laborTotal);
        subtotal = subtotal_num >= 0 ? `$${subtotal_num.toFixed(2)}` : "$0.00";
      } else {
        total_num = parseFloat(
          proposal.proposal_total ? proposal.proposal_total : "0",
        );
        subtotal_num = total_num - p_bond_num;
        subtotal = subtotal_num >= 0 ? `$${subtotal_num.toFixed(2)}` : "$0.00";
      }

      if (includeSalesTax) {
        total_num += parseFloat(addedSalesTax);
      }

      const total = `$${total_num.toFixed(2)}`;
      const p_bond = `$${p_bond_num.toFixed(2)}`;

      const submitted_by_name = `${submittedBy.first_name} ${submittedBy.last_name}`;
      const submitted_by_phone_no = submittedBy.cell_no ?? "";
      const submitted_by_email = submittedBy.email ?? "";

      const salesTaxRate = isExistingProposal
        ? proposal.material_sales_tax_rate
        : project.material_sales_tax_rate;

      let totalsBody = [];
      if (includeMFL) {
        totalsBody.push(
          [
            {
              text: "Material",
              fontSize: 11,
            },
            { text: `$${materialTotal}`, fontSize: 11, alignment: "right" },
          ],
          [
            {
              text: "Freight",
              fontSize: 11,
            },
            { text: `$${freightTotal}`, fontSize: 11, alignment: "right" },
          ],
          [
            {
              text: "Labor",
              fontSize: 11,
            },
            { text: `$${laborTotal}`, fontSize: 11, alignment: "right" },
          ],
          [
            {
              text: "Subtotal",
              fontSize: 11,
            },
            { text: subtotal, fontSize: 11, alignment: "right" },
          ],
        );
      } else {
        totalsBody.push([
          {
            text: includeSalesTax
              ? "Total Materials, Freight, and Installation Labor"
              : "Total Materials, Freight, Sales Tax, and Installation Labor",
            fontSize: 11,
            bold: p_bond_num > 0,
            fillColor: p_bond_num > 0 ? "white" : "#CCEEFF",
          },
          {
            text: subtotal,
            fontSize: 11,
            alignment: "right",
            bold: p_bond_num > 0,
            fillColor: p_bond_num > 0 ? "white" : "#CCEEFF",
          },
        ]);
      }

      if (p_bond_num > 0) {
        totalsBody.push(
          [
            {
              text: "Payment & Performance Bond (Not Included in Total)",
              fontSize: 11,
            },
            { text: p_bond, fontSize: 11, alignment: "right" },
          ],
          [
            {
              text: "Total Price Including Payment & Performance Bond",
              fontSize: 11,
              bold: true,
              fillColor: "#CCEEFF",
            },
            {
              text: total,
              fontSize: 11,
              bold: true,
              fillColor: "#CCEEFF",
              alignment: "right",
            },
          ],
        );
      }

      if (includeSalesTax && !includeMFL) {
        totalsBody.splice(
          1,
          0,
          [
            {
              text: "Taxable Subtotal",
              fontSize: 11,
            },
            {
              text: `$${taxableTotal.toFixed(2)}`,
              fontSize: 11,
              alignment: "right",
            },
          ],
          [
            {
              text: "Sales Tax Rate",
              fontSize: 11,
            },
            {
              text: `${salesTaxRate.toFixed(2)}%`,
              fontSize: 11,
              alignment: "right",
            },
          ],
          [
            {
              text: "Sales Tax",
              fontSize: 11,
            },
            {
              text: `$${addedSalesTax.toFixed(2)}`,
              fontSize: 11,
              alignment: "right",
            },
          ],
          [
            {
              text: "Total Price",
              fontSize: 11,
              bold: true,
              fillColor: p_bond_num > 0 ? "white" : "#CCEEFF",
            },
            {
              text: `$${totalAfterSalesTax.toFixed(2)}`,
              fontSize: 11,
              bold: true,
              fillColor: p_bond_num > 0 ? "white" : "#CCEEFF",
              alignment: "right",
            },
          ],
        );
      }

      if (includeSalesTax && includeMFL) {
        totalsBody.splice(
          4,
          0,
          [
            {
              text: "Taxable Subtotal",
              fontSize: 11,
            },
            {
              text: `$${taxableTotal.toFixed(2)}`,
              fontSize: 11,
              alignment: "right",
            },
          ],
          [
            {
              text: "Sales Tax Rate",
              fontSize: 11,
            },
            {
              text: `${salesTaxRate.toFixed(2)}%`,
              fontSize: 11,
              alignment: "right",
            },
          ],
          [
            {
              text: "Sales Tax",
              fontSize: 11,
            },
            {
              text: `$${addedSalesTax.toFixed(2)}`,
              fontSize: 11,
              alignment: "right",
            },
          ],
          [
            {
              text: "Total Price",
              fontSize: 11,
              bold: true,
              fillColor: p_bond_num > 0 ? "white" : "#CCEEFF",
            },
            {
              text: `$${totalAfterSalesTax.toFixed(2)}`,
              fontSize: 11,
              bold: true,
              fillColor: p_bond_num > 0 ? "white" : "#CCEEFF",
              alignment: "right",
            },
          ],
        );
      }

      const docDefinition = {
        content: [
          {
            image: is_ca_branch
              ? GENESIS_LOGO_BASE64
              : GENESIS_UTAH_LOGO_BASE64,
            width: is_ca_branch ? 200 : 250,
            height: is_ca_branch ? 80 : 100,
            alignment: "left",
            margin: [
              is_ca_branch ? COVER_PAGE_X_MARGIN : COVER_PAGE_X_MARGIN - 20,
              10,
              20,
              0,
            ],
          },
          {
            text: project_name,
            fontSize: 45,
            bold: true,
            color: "#003086",
            margin: [COVER_PAGE_X_MARGIN, 70, COVER_PAGE_X_MARGIN + 40, 0],
          },
          {
            columns: [
              {
                stack: [
                  {
                    text: is_ca_branch
                      ? "Genesis Floor Covering, Inc\n"
                      : "Genesis Floor Covering Utah, LLC\n",
                    fontSize: 14,
                    bold: "true",
                  },
                  {
                    text: branch_address_line_1,
                    fontSize: 14,
                    margin: [0, 2, 0, 0],
                  },
                  {
                    text: branch_address_line_2,
                    fontSize: 14,
                    margin: [0, 2, 0, 0],
                  },
                  {
                    text: branch_address_contractor_no,
                    fontSize: 14,
                    margin: [0, 2, 0, 0],
                  },
                  {
                    text: branch_address_dir_no,
                    fontSize: 14,
                    margin: [0, 2, 0, 0],
                  },
                  {
                    text: "www.genesisfloor.net",
                    fontSize: 14,
                    link: "https://www.genesisfloor.net",
                    color: HYPERLINK_COLOR,
                    margin: [0, 2, 0, 0],
                  },
                ],
              },
            ],
            absolutePosition: { x: COVER_PAGE_X_MARGIN + 42, y: 610 },
          },
          {
            text: projectStartYear,
            fontSize: 24,
            color: "white",
            bold: true,
            absolutePosition: { x: COVER_PAGE_X_MARGIN + 455, y: 770 },
          },
          {
            text: "",
            pageBreak: "after",
          },
          {
            columns: [
              {
                image: is_ca_branch
                  ? GENESIS_LOGO_BASE64
                  : GENESIS_UTAH_LOGO_BASE64,
                width: 200,
                height: 80,
                alignment: "left",
              },
              {
                text: [
                  is_ca_branch
                    ? "Genesis Floor Covering, Inc\n"
                    : "Genesis Floor Covering Utah, LLC\n",
                  branch_address_line_1,
                  branch_address_line_2,
                  branch_address_phone_no,
                  branch_address_contractor_no,
                  branch_address_dir_no,
                ],
                style: "headerRight",
                width: "*",
                alignment: "right",
              },
            ],
          },
          {
            columns: [
              {
                canvas: [
                  {
                    type: "line",
                    x1: 0,
                    y1: 0,
                    x2: 208,
                    y2: 0,
                    lineWidth: 1,
                    lineColor: SECONDARY_COLOR,
                  },
                ],
                width: "*",
                margin: [0, 10, 0, 0],
              },
              {
                text: "Proposal",
                style: "headerCenter",
                color: SECONDARY_COLOR,
                width: "auto",
                margin: [10, 0, 5, 5],
              },
              {
                canvas: [
                  {
                    type: "line",
                    x1: 0,
                    y1: 0,
                    x2: 208,
                    y2: 0,
                    lineWidth: 1,
                    lineColor: SECONDARY_COLOR,
                  },
                ],
                width: "*",
                margin: [0, 10, 0, 0],
              },
            ],
            columnGap: 10,
          },
          {
            margin: [0, 0, 0, 5],
            table: {
              widths: ["20%", "30%", "15%", "30%"],
              body: [
                [
                  {
                    text: "Customer:",
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                    lineHeight: 0.72,
                  },
                  { text: customer_name, fontSize: 10, lineHeight: 0.7 },
                  {
                    text: "Date:",
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                    lineHeight: 0.72,
                  },
                  { text: projectStartDate, fontSize: 10, lineHeight: 0.72 },
                ],
                [
                  {
                    text: "Company:",
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                    lineHeight: 0.84,
                  },
                  { text: company_name, fontSize: 10, lineHeight: 0.84 },
                  {
                    text: "Project:",
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                    lineHeight: 0.84,
                  },
                  { text: project_name, fontSize: 10, lineHeight: 0.84 },
                ],
                [
                  {
                    text: "Address:",
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                    lineHeight: 0.7,
                  },
                  {
                    text: company_address_line_1,
                    fontSize: 10,
                    lineHeight: 0.7,
                  },
                  {
                    text: "Location:",
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                    lineHeight: 0.7,
                  },
                  { text: location_line_1, fontSize: 10, lineHeight: 0.7 },
                ],
                [
                  "",
                  {
                    text: company_address_line_2,
                    fontSize: 10,
                    lineHeight: 0.74,
                  },
                  "",
                  { text: location_line_2, fontSize: 10, lineHeight: 0.74 },
                ],
                [
                  {
                    text: "Telephone:",
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                  },
                  { text: customer_phone_no, fontSize: 10 },
                  "",
                  "",
                ],
              ],
            },
            layout: "noBorders",
          },
          {
            text: [
              {
                text: "We herewith submit proposal for labor and materials to be supplied at the request of above party and at premises set forth above. ",
                bold: true,
                fontSize: 10,
              },
              {
                text: ca_wages_clause,
                italics: true,
                fontSize: 10,
                bold: true,
              },
            ],
            margin: [0, 2],
          },
          {
            table: {
              widths: [0, "*", 0],
              body: [
                [
                  {
                    text: "",
                    fillColor: "#E6F4FF",
                  },
                  {
                    text: [
                      { text: "AREA INCLUDED IN BID: ", bold: true },
                      { text: includedArea },
                    ],
                    fontSize: 10,
                    fillColor: "#E6F4FF",
                    margin: [-2, 0, 0, 0],
                  },
                  {
                    text: "",
                    fillColor: "#E6F4FF",
                  },
                ],
                [
                  {
                    text: "",
                    fillColor: "#E6F4FF",
                  },
                  {
                    text: "SCOPE OF WORK:",
                    bold: true,
                    fontSize: 10,
                    margin: [7, 5, 0, 0],
                    fillColor: "#E6F4FF",
                  },
                  {
                    text: "",
                    fillColor: "#E6F4FF",
                  },
                ],
                [
                  {
                    text: "",
                    fillColor: "#E6F4FF",
                  },
                  {
                    stack: isItemized
                      ? [generateItemizedTable(inclusions)]
                      : generateUnItemizedTable(inclusions),
                    fillColor: "#E6F4FF",
                  },
                  {
                    text: "",
                    fillColor: "#E6F4FF",
                  },
                ],
                [
                  {
                    text: "",
                    fillColor: "#E6F4FF",
                  },
                  {
                    text: "EXCLUSIONS:",
                    bold: true,
                    fontSize: 10,
                    margin: [7, 5, 0, -3],
                    fillColor: "#E6F4FF",
                  },
                  {
                    text: "",
                    fillColor: "#E6F4FF",
                  },
                ],
                [
                  {
                    text: "",
                    fillColor: "#E6F4FF",
                  },
                  {
                    ol: exclusions.map((exclusion) =>
                      exclusion.description.trim(),
                    ),
                    margin: [10, 0, 0, 3],
                    fontSize: 9,
                    fillColor: "#E6F4FF",
                  },
                  {
                    text: "",
                    fillColor: "#E6F4FF",
                  },
                ],
                [
                  {
                    text: "",
                    fillColor: "#E6F4FF",
                  },
                  {
                    text: "",
                    fillColor: "#E6F4FF",
                  },
                  {
                    text: "",
                    fillColor: "#E6F4FF",
                  },
                ],
              ],
            },
            layout: {
              hLineWidth: function (i, node) {
                return i === 0 || i === node.table.body.length ? 1 : 0;
              },
              vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 1 : 0;
              },
              hLineColor: function (i, node) {
                return i === 0 || i === node.table.body.length
                  ? SECONDARY_COLOR
                  : "white";
              },
              vLineColor: function (i, node) {
                return i === 0 || i === node.table.widths.length
                  ? SECONDARY_COLOR
                  : "white";
              },
              paddingLeft: function (i, node) {
                return 4;
              },
              paddingRight: function (i, node) {
                return 4;
              },
              paddingTop: function (i, node) {
                return i == 0 ? 5 : 0;
              },
              paddingBottom: function (i, node) {
                return 4;
              },
            },
          },
          {
            margin: [0, 6],
            table: {
              widths: ["*", "auto"],
              body: totalsBody,
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0.5;
              },
              vLineWidth: function (i, node) {
                return 0.5;
              },
              hLineColor: function (i, node) {
                return SECONDARY_COLOR;
              },
              vLineColor: function (i, node) {
                return SECONDARY_COLOR;
              },
            },
          },
          {
            text: "NOTES:",
            bold: true,
            fontSize: 8,
          },
          {
            ol: notes.map((note) => note.description.trim()),
            margin: [10, 0, 0, 2],
            fontSize: 8,
          },
          {
            table: {
              widths: ["14%", "*", "14%", "*"],
              body: [
                [
                  {
                    text: "Submitted By:",
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                    margin: [0, 20, 0, 30],
                  },
                  {
                    text: submitted_by_name,
                    font: "Courgette",
                    bold: true,
                    fontSize: 14,
                    margin: [0, 16, 0, 30],
                  },
                  {
                    text: "Tel:",
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                    margin: [0, 20, 0, 30],
                  },
                  {
                    text: submitted_by_phone_no,
                    fontSize: 10,
                    margin: [0, 20, 0, 30],
                  },
                ],
                [
                  {
                    text: "Accepted By:",
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                  },
                  { text: "___________________________________", fontSize: 10 },
                  {
                    text: "Date:",
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                  },
                  { text: "___________________________", fontSize: 10 },
                ],
              ],
            },
            layout: "noBorders",
          },
        ],
        background: (currentPage, pageSize) => {
          if (currentPage === 1) {
            return {
              image: BUSINESS_BG_BASE64,
              width: pageSize.width,
              height: pageSize.height,
              absolutePosition: { x: 0, y: 0 },
            };
          }
        },
        styles: {
          headerRight: {
            alignment: "right",
            fontSize: 9,
            color: SECONDARY_COLOR,
          },
          headerCenter: {
            bold: true,
            fontSize: 18,
            alignment: "center",
            margin: [0, 18, 0, 20],
          },
        },
        defaultStyle: {
          columnGap: 20,
        },
      };

      const pdfBlob = await getPDFBlob(docDefinition);
      const fileName = proposalName + ".pdf";

      const { data } = await decoratedAxiosPost(
        "/get_s3_presigned_urls_for_upload",
        {
          project_id: project.id,
          files: [
            {
              name: fileName,
              type: "application/pdf",
            },
          ],
        },
      );
      const newFile = {
        name: fileName,
        type: "pdf",
        s3_key: data[0].s3_key,
      };

      const res = await decoratedAxiosPost("/create_proposal", {
        only_create_file: isExistingProposal,
        project_id: project.id,
        name: proposalName,
        genesis_branch_address_id: project?.genesis_branch_address?.id,
        customer_id: customer.id,
        location_id: location.id,
        start_date: startDate,
        submitted_by_employee_id: submittedBy.id,
        material_subtotal: materialTotal,
        material_markup_rate: proposal.material_markup_rate,
        material_sales_tax_rate: proposal.material_sales_tax_rate,
        labor_subtotal: laborTotal,
        labor_markup_rate: proposal.labor_markup_rate,
        labor_sales_tax_rate: proposal.labor_sales_tax_rate,
        freight_subtotal: freightTotal,
        p_and_p_bond: proposal.p_and_p_bond,
        proposal_total: proposal.proposal_total,
        included_area: includedArea,
        file: newFile,
      });
      const newProposal = isExistingProposal ? null : res.data;

      const result = await axios.put(data[0].presigned_url, pdfBlob, {
        headers: {
          "Content-Type": "application/pdf",
        },
      });

      if (!isExistingProposal) {
        dispatchState(selectProposalAndRefreshProposals(newProposal));
      }

      try {
        const { data } = await decoratedAxiosPost(
          "/get_s3_presigned_url_for_download",
          {
            s3_key: newFile.s3_key,
          },
        );
        setLoading(false);
        handleCloseProp(true, newFile, data.presigned_url);
      } catch (error) {
        console.error("Error previewing file:", error);
        handleCloseProp(true);
      }
    } catch (error) {
      setLoading(false);
      if (error.response?.data?.msg) {
        setAlertMessage(error.response.data.msg);
      } else {
        setAlertMessage("An error occurred when creating proposal");
      }
      console.error("Error creating proposal:", error);
    }
  };

  const onProposalNameChange = (newName) => {
    setProposalNameError(false);
    setProposalName(newName);
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    const listProposalTemplates = () => {
      decoratedFetch("/list_proposal_templates")
        .then((response) => response.json())
        .then((data) => {
          setProposalTemplates(data); // Set the new array to the state
        })
        .catch((error) => {
          console.error(`Error listing proposal templates: ${error}`);
        });
    };

    listProposalTemplates();
  }, [open]);

  const importMaterialItems = () => {
    decoratedFetch(
      `/list_project_material_items?${isExistingProposal ? "proposal_id" : "project_id"}=${isExistingProposal ? proposal.id : project.id}`,
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length > 0) {
          const newRows = data.map((item, index) => ({
            id: nextInclusionId + index, // Use nextInclusionId for unique IDs
            quantity: item?.quantity,
            unit: item.size_unit?.name,
            unitPrice: `$${item?.unit_price}`,
            category: "Material",
            description: `Furnish ${item.material_vendor} ${item.material_type} ${item.material_style} ${item.material_color}`,
            extension: `$${(item?.quantity * parseFloat(item?.unit_price)).toFixed(2)}`,
          }));

          setInclusions((currentRows) => [...currentRows, ...newRows]);
          setNextInclusionId(nextInclusionId + newRows.length); // Update nextInclusionId state
        }
      })
      .catch((error) => {
        console.error(`Error listing project materials: ${error}`);
      });
  };

  const importFreightItems = () => {
    decoratedFetch(
      `/list_project_freight_items?${isExistingProposal ? "proposal_id" : "project_id"}=${isExistingProposal ? proposal.id : project.id}`,
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length > 0) {
          const newRows = data.map((item, index) => ({
            id: nextInclusionId + index, // Use nextInclusionId for unique IDs
            quantity: item?.quantity,
            unit: item.size_unit?.name,
            unitPrice: `$${item?.unit_price}`,
            category: "Freight",
            description: `${item.freight_item_type} ${item.description}`,
            extension: `$${(item?.quantity * parseFloat(item?.unit_price)).toFixed(2)}`,
          }));

          setInclusions((currentRows) => [...currentRows, ...newRows]);
          setNextInclusionId(nextInclusionId + newRows.length); // Update nextInclusionId state
        }
      })
      .catch((error) => {
        console.error(`Error listing project materials: ${error}`);
      });
  };

  const importLaborItems = () => {
    decoratedFetch(
      `/list_project_demo_items?${isExistingProposal ? "proposal_id" : "project_id"}=${isExistingProposal ? proposal.id : project.id}`,
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length > 0) {
          const newRows = data.map((item, index) => ({
            id: nextInclusionId + index, // Use nextInclusionId for unique IDs
            quantity: item?.quantity,
            unit: item.size_unit?.name,
            unitPrice: `$${item?.unit_price}`,
            category: "Labor",
            description: `${item.labor_item_type.name} ${item.demo_item_type}`,
            extension: `$${(item?.quantity * parseFloat(item?.unit_price)).toFixed(2)}`,
          }));

          setInclusions((currentRows) => [...currentRows, ...newRows]);
          setNextInclusionId(nextInclusionId + newRows.length); // Update nextInclusionId state
        }
      })
      .catch((error) => {
        console.error(`Error listing project materials: ${error}`);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      disableEscapeKeyDown
      disableScrollLock
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        {isExistingProposal ? "Create Proposal PDF" : "Create Proposal"}
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={1}
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1000,
            backgroundColor: "white",
          }}
        >
          {alertMessage && (
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Alert
                onClose={() => setAlertMessage("")}
                severity={"error"}
                sx={{
                  width: "80%",
                  borderRadius: 8,
                  marginBottom: "15px",
                  marginTop: "4px",
                }}
              >
                {alertMessage}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <TextField
              label={isExistingProposal ? "File Name" : "Proposal Name"}
              variant="outlined"
              fullWidth
              size="small"
              value={proposalName}
              onChange={(e) => onProposalNameChange(e.target.value)}
              error={proposalNameError}
              helperText={proposalNameError ? "Required" : ""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="outlined"
              sx={{
                minWidth: 120,
                width: "100%",
              }}
              size="small"
            >
              <InputLabel>Template</InputLabel>
              <Select
                id="template-select"
                value={proposalTemplate}
                onChange={handleProposalTemplateChange}
                label="Template"
                fullWidth
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {proposalTemplates.map((template) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ mt: "14px", mb: "12px" }}>
            <TextField
              label="Area Included in Bid"
              variant="outlined"
              fullWidth
              size="small"
              value={includedArea}
              onChange={(e) => setIncludedArea(e.target.value)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", justifyContent: "space-around" }}
          >
            <Button
              size="small"
              onClick={importMaterialItems}
              fullWidth
              sx={{ pt: "8px" }}
            >
              Import Materials
            </Button>
            <Button
              size="small"
              onClick={importLaborItems}
              fullWidth
              sx={{ pt: "8px" }}
            >
              Import Labor
            </Button>
            <Button
              size="small"
              onClick={importFreightItems}
              fullWidth
              sx={{ pt: "8px" }}
            >
              Import Freight
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", justifyContent: "space-around" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={isItemized}
                  onChange={handleIsItemizedChecked}
                />
              }
              label="Itemize"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeMFL}
                  onChange={handleIncludeMFL}
                  disabled={!isItemized}
                />
              }
              label="MFL Breakdown"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeSalesTax}
                  onChange={(e) => setIncludeSalesTax(e.target.checked)}
                  disabled={!isItemized}
                />
              }
              label="Sales Tax"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sx={{
              mt: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "1.2rem" }}>Inclusions</Typography>
            <Grid container justifyContent="flex-end">
              <Grid
                item
                sx={{
                  marginRight: "4px",
                  visibility: selectedInclusions.length ? "visible" : "hidden",
                }}
              >
                {loading ? (
                  <CircularProgress />
                ) : (
                  <IconButton
                    aria-label="remove-inclusion"
                    onClick={handleRowDelete}
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="add-inclusion"
                  onClick={handleAddInclusionRow}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mb: "11px", width: "100%" }}>
            {inclusions.length ? (
              <DataGridPro
                sx={{
                  "& .MuiDataGrid-columnHeaders": {
                    display: isItemized ? "block" : "none",
                  },
                }}
                hideFooter
                rows={inclusions}
                columns={isItemized ? itemizedColumns : descriptionColumns}
                processRowUpdate={handleRowEditCommit}
                onProcessRowUpdateError={(error) => console.error(error)}
                experimentalFeatures={{ newEditingApi: true }}
                checkboxSelection
                onRowSelectionModelChange={(newSelectionModel) =>
                  handleInclusionRowSelectionModelChange(newSelectionModel)
                }
                getRowId={(row) => row.id}
                onCellClick={(params, e) => e.stopPropagation()}
                localeText={{ noRowsLabel: "No Inclusions" }}
                onCellEditStop={handleCellEditStop}
                rowReordering
								onRowOrderChange={e => {
									let mutableInclusions = [...inclusions];
									const sourceRow = mutableInclusions[e.oldIndex];
									mutableInclusions.splice(e.oldIndex, 1);
									mutableInclusions.splice(e.targetIndex, 0, sourceRow);
									setInclusions(mutableInclusions);
								}}
              />
            ) : null}
          </Grid>
          {includeMFL && (
            <>
              <Grid
                item
                xs={12}
                sm={3.25}
                md={3}
                sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}
              >
                <Typography
                  sx={{ fontSize: { xs: "11px", sm: "13px", md: "16px" } }}
                >
                  Material Total: <strong>${materialTotal}</strong>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}
              >
                <Typography
                  sx={{ fontSize: { xs: "11px", sm: "13px", md: "16px" } }}
                >
                  Labor Total: <strong>${laborTotal}</strong>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={3.5}
                sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}
              >
                <Typography
                  sx={{ fontSize: { xs: "11px", sm: "13px", md: "16px" } }}
                >
                  Freight Total: <strong>${freightTotal}</strong>
                </Typography>
              </Grid>
            </>
          )}
          {includeSalesTax && (
            <>
              <Grid
                item
                xs={12}
                sm={3.25}
                md={3}
                sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}
              >
                <Typography
                  sx={{ fontSize: { xs: "11px", sm: "13px", md: "16px" } }}
                >
                  Taxable Total: <strong>${taxableTotal.toFixed(2)}</strong>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}
              >
                <Typography
                  sx={{ fontSize: { xs: "11px", sm: "13px", md: "16px" } }}
                >
                  Added Sales Tax: <strong>${addedSalesTax.toFixed(2)}</strong>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={3.5}
                sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}
              >
                <Typography
                  sx={{ fontSize: { xs: "11px", sm: "13px", md: "16px" } }}
                >
                  Total: <strong>${totalAfterSalesTax.toFixed(2)}</strong>
                </Typography>
              </Grid>
            </>
          )}
          <Grid
            item
            xs={12}
            sx={{
              mt: includeMFL || includeSalesTax ? "10px" : "0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "1.2rem" }}>Exclusions</Typography>
            <Grid container justifyContent="flex-end">
              <Grid
                item
                sx={{
                  marginRight: "4px",
                  visibility: selectedExclusions.length ? "visible" : "hidden",
                }}
              >
                {loading ? (
                  <CircularProgress />
                ) : (
                  <IconButton
                    aria-label="remove-exclusion-item"
                    onClick={handleExclusionRowDelete}
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="add-exclusion-item"
                  onClick={handleAddExclusionRow}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mb: "11px", width: "100%" }}>
            {exclusions.length ? (
              <DataGridPro
                sx={{
                  "& .MuiDataGrid-columnHeaders": {
                    display: "none",
                  },
                }}
                hideFooter
                rows={exclusions}
                columns={descriptionColumns}
                processRowUpdate={handleExclusionEditCommit}
                onProcessRowUpdateError={(error) => console.error(error)}
                experimentalFeatures={{ newEditingApi: true }}
                checkboxSelection
                onRowSelectionModelChange={
                  handleExclusionRowSelectionModelChange
                }
                getRowId={(row) => row.id}
                onCellClick={(params, e) => e.stopPropagation()}
                localeText={{ noRowsLabel: "No Exclusions" }}
                rowReordering
								onRowOrderChange={e => {
									let mutableExclusions = [...exclusions];
									const sourceRow = mutableExclusions[e.oldIndex];
									mutableExclusions.splice(e.oldIndex, 1);
									mutableExclusions.splice(e.targetIndex, 0, sourceRow);
									setExclusions(mutableExclusions);
								}}
              />
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "1.2rem" }}>Notes</Typography>
            <Grid container justifyContent="flex-end">
              <Grid
                item
                sx={{
                  marginRight: "4px",
                  visibility: selectedNotes.length ? "visible" : "hidden",
                }}
              >
                {loading ? (
                  <CircularProgress />
                ) : (
                  <IconButton
                    aria-label="remove-description-item"
                    onClick={handleNoteRowDelete}
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="add-description-item"
                  onClick={handleAddNoteRow}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mb: "11px", width: "100%" }}>
            {notes.length ? (
              <DataGridPro
                sx={{
                  "& .MuiDataGrid-columnHeaders": {
                    display: "none",
                  },
                }}
                hideFooter
                rows={notes}
                columns={descriptionColumns}
                processRowUpdate={handleNoteEditCommit}
                onProcessRowUpdateError={(error) => console.error(error)}
                experimentalFeatures={{ newEditingApi: true }}
                checkboxSelection
                onRowSelectionModelChange={handleNoteRowSelectionModelChange}
                getRowId={(row) => row.id}
                rowReordering
                localeText={{ noRowsLabel: "No Notes" }}
								onRowOrderChange={e => {
									let mutableNotes = [...notes];
									const sourceRow = mutableNotes[e.oldIndex];
									mutableNotes.splice(e.oldIndex, 1);
									mutableNotes.splice(e.targetIndex, 0, sourceRow);
									setNotes(mutableNotes);
								}}
              />
            ) : null}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {loading ? (
          <CircularProgress
            size="25px"
            sx={{ textAlign: "center", mt: "6px", mb: "5.5px", mr: "30px" }}
          />
        ) : (
          <>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" onClick={handleSubmit}>
              Create
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreateProposalDialog;
