import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Autocomplete,
  FormControl,
  InputLabel,
  CircularProgress,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import InputAdornment from "@mui/material/InputAdornment";
import { decoratedFetch } from "../../req_utils";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { SUPPORT_EMAIL } from "../../constants";

const LABEL_COLOR = "#7A7A7A";
const BORDER_COLOR = "#C5C5C5";

export function CreateDemoItemDialog({
  isOpen,
  handleClose: handleCloseProp,
  demoItem,
  handleSubmit: handleSubmitProp,
  setSnackbarOpen,
  setSnackbarMessage,
  proposalId,
}) {
  let { project_id } = useParams();
  const [demoItemType, setDemoItemType] = useState("");
  const [labor_item_type, setLaborItemType] = useState("");
  const [labor_item_types, setLaborItemTypes] = useState([]);
  const [types, setDemoItemTypes] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [displayQuantity, setDisplayQuantity] = useState("");
  const [unit, setUnit] = useState("");
  const [units, setUnits] = useState([]);
  const [unitPrice, setUnitPrice] = useState("");
  const [totalPrice, setTotalPrice] = useState(parseFloat(0).toFixed(2));
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAddToSavedChecked, setIsAddToSavedChecked] = useState(false);
  const [savedLaborItems, setSavedLaborItems] = useState([]);
  const [savedLaborItem, setSavedLaborItem] = useState({});

  const handleCheckboxChange = (event) => {
    setIsAddToSavedChecked(event.target.checked);
  };

  useEffect(() => {
    if (savedLaborItem && Object.keys(savedLaborItem).length > 0) {
      setLaborItemType(savedLaborItem.labor_item_type.id || "");
      setDemoItemType(savedLaborItem?.demo_item_type || "");
      setUnit(savedLaborItem.size_unit.id || "");
      setUnitPrice(savedLaborItem?.unit_price || "");
    }
  }, [savedLaborItem]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    if (demoItem) {
      initializeFromDemoItemProp();
    } else {
      resetFields();
    }
    decoratedFetch("/list_demo_item_types")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setDemoItemTypes(data))
      .catch((error) => console.error(`Error fetching labor types ${error}`));
    decoratedFetch("/list_labor_item_types")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setLaborItemTypes(data))
      .catch((error) => console.error(`Error fetching labor types ${error}`));
    decoratedFetch("/list_size_units")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setUnits(data))
      .catch((error) => console.error(`Error fetching size units ${error}`));
    decoratedFetch("/list_saved_labor_items")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => {
        setSavedLaborItems(data);
      })
      .catch((error) => {
        console.error(`Error fetching saved labor items: ${error}`);
      });
  }, [isOpen]);

  const resetFields = () => {
    setDemoItemType("");
    setLaborItemType("");
    setQuantity(0);
    setDisplayQuantity("");
    setUnit("");
    setUnitPrice("");
    setTotalPrice(parseFloat(0).toFixed(2));
    setLoading(false);
    setAlertMessage("");
    setSavedLaborItem(null);
    setIsAddToSavedChecked(false);
  };

  const initializeFromDemoItemProp = () => {
    setDemoItemType(demoItem.demo_item_type);
    setLaborItemType(demoItem.labor_item_type.id);
    setQuantity(demoItem?.quantity);
    setDisplayQuantity(demoItem?.quantity.toString());
    setUnit(demoItem?.size_unit.id);
    setUnitPrice(demoItem?.unit_price);
    setTotalPrice(demoItem?.total_price);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (!demoItemType || !unit || !labor_item_type) {
      setAlertMessage(
        "Required fields not filled in. Must specify a labor demoItemType, item demoItemType and unit",
      );
      setLoading(false);
      return;
    }
    const api = demoItem
      ? `/update_project_demo_item/${demoItem.id}`
      : "/create_project_demo_item";
    decoratedFetch(api, {
      method: demoItem ? "PUT" : "POST",
      body: JSON.stringify({
        project_id: project_id,
        proposal_id: proposalId,
        labor_item_type_id: labor_item_type,
        type: demoItemType,
        quantity: quantity,
        unit: unit,
        unit_price: unitPrice ? unitPrice : parseFloat(0).toFixed(2),
        total_price: totalPrice,
        save: isAddToSavedChecked,
      }),
    })
      .then((response) => {
        if (!demoItem && response.status === 201) {
          return;
        }
        if (demoItem && response.status === 200) {
          return;
        }
        switch (response.status) {
          case 400:
            setAlertMessage(
              "Required fields not filled in. DemoItems must have the following: Type, First Name, Last Name",
            );
            break;
          case 500:
            setAlertMessage(
              `Internal server error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
            break;
          default:
            setAlertMessage(
              `Unknown error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
        }
        throw new Error();
      })
      .then(() => {
        setLoading(false);
        handleSubmitProp(
          parseFloat(totalPrice),
          demoItem ? parseFloat(demoItem.total_price) : 0,
          proposalId,
        );
        handleClose();
        setSnackbarOpen(true);
        setSnackbarMessage(`Labor item ${demoItem ? "updated" : "created"}`);
      })
      .catch((error) => {
        console.error(`Error ${demoItem ? "editing" : "creating"} labor item`);
        setAlertMessage(
          `An error occurred when ${demoItem ? "editing" : "creating"} labor item`,
        );
        setLoading(false);
      });
  };

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    resetFields();
    setAlertMessage("");
    handleCloseProp();
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage("");
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} disableScrollLock>
      <DialogTitle sx={{ fontSize: "22px", paddingBottom: "0px", flexGrow: 1 }}>
        {demoItem ? "Edit" : "Create"} Item
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="row" marginTop={1}>
          {alertMessage && (
            <Grid item xs={12}>
              <Alert
                onClose={handleAlertClose}
                severity="error"
                sx={{ width: "100%", borderRadius: 8 }}
              >
                {alertMessage}
              </Alert>
            </Grid>
          )}
          {!demoItem && (
            <Grid item xs={12}>
              <Autocomplete
                id="saved-labor-item-autocomplete"
                options={savedLaborItems}
                getOptionLabel={(option) =>
                  `${option.labor_item_type.name || ""} ${option?.demo_item_type || ""} ${option?.size_unit?.name || ""} ${option?.unit_price || ""}`
                }
                value={savedLaborItem}
                onChange={(event, newValue) => {
                  setSavedLaborItem(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Saved Labor Item"
                    variant="outlined"
                    fullWidth
                    placeholder="Select saved labor item"
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <FormControl
              variant="outlined"
              sx={{
                minWidth: 120,
                width: "100%",
              }}
            >
              <InputLabel>Labor Type</InputLabel>
              <Select
                id="labor-demoItemType-select"
                value={labor_item_type}
                onChange={(e) => setLaborItemType(e.target.value)}
                label="Labor Type"
                fullWidth
              >
                {labor_item_types.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="Item"
              value={demoItemType}
              freeSolo
              options={types.map((option) => option.name)}
              renderInput={(params) => <TextField {...params} label="Item" />}
              onInputChange={(event, newInputValue, reason) => {
                if (reason === "input") {
                  setDemoItemType(newInputValue);
                }
              }}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setDemoItemType(newValue);
                }
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Quantity"
              demoItemType="number"
              value={displayQuantity}
              onChange={(e) => {
                const displayVal = e.target.value;
                setDisplayQuantity(displayVal);
                const intVal = displayVal ? parseInt(displayVal) : 0;
                setQuantity(intVal);
                setTotalPrice(
                  (intVal * parseFloat(unitPrice ? unitPrice : 0)).toFixed(2),
                );
              }}
              InputLabelProps={{ shrink: displayQuantity }}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl
              variant="outlined"
              sx={{
                minWidth: 120,
                width: "100%",
              }}
            >
              <InputLabel>Unit</InputLabel>
              <Select
                id="unit-select"
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                label="Unit"
                fullWidth
              >
                {units.map((unit) => (
                  <MenuItem key={unit.id} value={unit.id}>
                    {unit.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Unit Price"
              demoItemType="number"
              value={unitPrice}
              onChange={(e) => {
                const val = e.target.value;
                setUnitPrice(val);
                setTotalPrice((quantity * parseFloat(val)).toFixed(2));
              }}
              onBlur={() =>
                setUnitPrice(parseFloat(unitPrice).toFixed(2).toString())
              }
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            {project_id && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAddToSavedChecked}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Add to Saved Labor Items"
                sx={{ width: "100%" }}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "space-between",
          margin: "5px",
          marginLeft: "10px",
        }}
      >
        <Typography
          variant="p"
          id="total-demo-item-price"
          sx={{ float: "left" }}
        >
          Total price: <b>${totalPrice}</b>
        </Typography>
        {loading ? (
          <CircularProgress
            size="25px"
            sx={{ textAlign: "center", mt: "6px", mb: "5.5px", mr: "20px" }}
          />
        ) : (
          <div>
            <Button
              sx={{ marginRight: "10px" }}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={alertMessage}
            >
              {demoItem ? "Save" : "Create"}
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}
